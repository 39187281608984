<template>
  <div>
    <div>
      <section class="mb-4">
        <small class="d-block mb-3 d-flex align-items-center">
          <b-icon class="h4 mr-2" icon="exclamation-circle"></b-icon>
          Adicionar a quantidade de empregados por cargos. Ao adicionar CARGO, você colocará o nome do cargo, por exemplo:
          ANALISTA OPERACIONAL e a quantidade de empregados nessa função. Informará se é OPERACIONAL ou ADMINISTRATIVO.
          Essa informação será extraída da sua área de RH e deverá se com base na ultima folha fechada. O objetivo e
          conhecermos a estrutura organizacional da sua empresa.
        </small>
        <section class="d-flex justify-content-between align-items-center mb-2">
          <div class="input-search col-md-5 p-0">
            <b-form-input @input="handleSearch" v-model="search" placeholder="Digite para pesquisar" />

            <b-button variant="light" class="px-2 d-flex align-items-center absolute icon-action">
              <b-icon icon="search"></b-icon>
            </b-button>
          </div>
          <b-button class="btn-add mb-0" @click="handleAddNewPosition()">
            Adicionar
          </b-button>
        </section>

        <section>
          <div class="d-flex mt-3">
            <b-button v-for="(positionType, index) in positionsTypes" :key="index" size="sm"
              :variant="type === index ? 'secondary' : 'light'" class="mr-2" @click="handleChangeFilter(index)">
              {{ positionType }}
            </b-button>
          </div>
        </section>
      </section>
      <empty-list text="Não há cargos para serem exibidos" v-if="empty" />
      <cards-list grid="4">
        <b-card no-body class="px-3 py-2 mb-3 mr-md-3" v-for="(position, index) in positions" :key="index">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <avatar :name="position.name" />
            <div class="d-flex align-items-center">
              <b-icon @click="handleSelectDelete(position.id)" v-b-popover.hover.top="'Excluir'"
                class="h6 mx-1 pointer mb-0" icon="trash" />
              <b-icon class="h6 mx-1 pointer mb-0" icon="pencil-square" @click="handleEditPosition(position)" />

              <b-modal :id="`delete-row_${position.id}`" centered size="sm">
                <template #modal-header>
                  <h5>Cuidado!</h5>
                </template>
                <p>
                  Você apagará o registro selecionado. Essa ação não poderá ser
                  desfeita, deseja continuar?
                </p>
                <template #modal-footer="{ close }">
                  <b-button variant="link" @click="handleDeleteRow()">
                    Sim
                  </b-button>
                  <b-button variant="primary" @click="close()">Não</b-button>
                </template>
              </b-modal>
            </div>
          </div>

          <strong class="d-bloc my-2">
            {{ position.name }}
          </strong>
          <span class="flex-1">
            <b-icon icon="person" />
            <strong>{{ position.quantity }}</strong> colaboradores</span>
        </b-card>
      </cards-list>
    </div>
    <b-modal id="add-employee" centered hide-footer size="md" :title="`${isAdd ? 'Adicionar cargo' : `Editar ${this.selectedEmployee.name}`}`
      ">
      <Form :id="selectedId" :isAdd="isAdd" @submited="handleCloseModal" />
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Form from "./form.vue";

export default {
  components: {
    Form,
  },
  data() {
    return {
      isAdd: false,
      empty: false,
      legal_personnel: "",
      positionsTypes: ["Todos", "Administrativo", "Operacionais"],
      selectedEmployee: { id: "" },
      selectedId: null,
      search: "",
      type: 0,
      positions: [],
    };
  },
  computed: {
    ...mapGetters(["user", "is_provider", "is_preview", "preview_personify"]),
  },
  methods: {
    ...mapActions([
      "get_all_jobs_position",
      "delete_jobs_position",
      "show_delete",
    ]),
    getListJobsPosition() {
      const params = {
        search: this.search,
        type: this.type.toString(),
      };

      if (this.search.length === 0) {
        delete params.search;
      }

      if (this.type === 0) {
        delete params.type;
      }

      this.get_all_jobs_position({ ...params }).then(({ data }) => {
        this.positions = data.results ?? [];
        this.empty = this.positions.length === 0;
      });
    },

    handleChangeFilter(type) {
      this.type = type;
      this.getListJobsPosition();
    },
    handleAddNewPosition() {
      this.isAdd = true;
      this.selectedId = this.selectedEmployee.id;
      this.$bvModal.show("add-employee");
    },
    handleCloseModal() {
      this.$bvModal.hide("add-employee");
      this.getListJobsPosition();
    },
    handleEditPosition(position) {
      this.isAdd = false;
      this.selectedEmployee = position;
      this.selectedId = position.id;
      this.$bvModal.show("add-employee");
    },

    handleSelectDelete(id) {
      this.selectedId = id;
      this.show_delete({
        in_delete: true,
        message:
          "Você apagará o cargo selecionado. Essa ação não poderá ser desfeita, deseja continuar?",
      });
    },

    handleSearch: _.debounce(function (value) {
      this.search = value;
      this.getListJobsPosition();
    }, 500),

    handleDeleteJobsPosition() {
      this.delete_jobs_position({
        id: this.selectedId,
      }).then(() => {
        this.getListJobsPosition();
        this.show_delete({
          in_delete: false,
        });
      });
    },
  },
  mounted() {
    this.getListJobsPosition();
    this.$root.$on("confirm-close-delete", (isDelete) => {
      if (isDelete) {
        this.handleDeleteJobsPosition();
      }
    });
  },
};
</script>
