<template>
  <div>
    <b-form @submit.prevent="handleSubmitPosition()">
      <b-form-group :label="$t('general.name')">
        <b-form-input
          v-model="$v.name.$model"
          :class="$v.name.$error && 'is-invalid'"
          trim
        >
          <b-form-invalid-feedback v-if="!$v.name.required">
            {{ $t("auth.type-valid-name") }}
          </b-form-invalid-feedback>
        </b-form-input>
      </b-form-group>
      <b-form-group label="Quantidade">
        <b-form-input
          type="number"
          v-model="$v.quantity.$model"
          :class="$v.quantity.$error && 'is-invalid'"
          trim
        ></b-form-input>
        <b-form-invalid-feedback v-if="!$v.quantity.required">
          Digite uma quantidade válida
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Posição">
        <div class="d-flex">
          <b-button
            class="mr-2"
            @click="type = 1"
            size="sm"
            :variant="type === 1 ? 'success' : 'light'"
          >
            Administrativa
          </b-button>
          <b-button
            @click="type = 2"
            size="sm"
            :variant="type === 2 ? 'success' : 'light'"
          >
            Operacional
          </b-button>
        </div>
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button variant="link" @click="$emit('submited')">Cancelar</b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="isLoading"
          class="pull-right text-right"
        >
          <b-spinner v-if="isLoading" small class="mr-2" />
          <span> {{ $t("general.save") }}</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  props: {
    id: {
      type: [String, Number],
    },
    isAdd: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      name: null,
      quantity: null,
      type: null,
      admin: false,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(4),
    },
    type: {
      required,
    },
    quantity: {
      required,
    },
  },
  computed: {
    ...mapGetters(["user", "is_preview", "preview_personify"]),
  },
  methods: {
    ...mapActions([
      "add_jobs_position",
      "get_jobs_position",
      "show_toast",
      "edit_jobs_position",
    ]),
    handleSubmitPosition() {
      this.isLoading = true;
      if (!this.$v.$anyError && !this.$v.$anyError) {
        this.$v.$touch();
        if (this.isAdd) {
          this.saveUser();
        } else {
          this.editUser();
        }
      }
    },
    saveUser() {
      this.add_jobs_position({
        name: this.name,
        quantity: this.quantity,
        type: this.type,
        provider: this.is_preview
          ? this.preview_personify?.id
          : this.user?.enterprise?.id,
      })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Cadastro realizado com sucesso",
              type: "success",
            });
            this.$emit("submited");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.show_toast({
            message: err,
            type: "error",
          });
        });
    },
    editUser() {
      this.edit_jobs_position({
        id: this.id,
        name: this.name,
        quantity: this.quantity,
        type: this.type,
        provider: this.is_preview
          ? this.preview_personify?.id
          : this.user?.enterprise?.id,
      })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.show_toast({
              message: "Edição realizada com sucesso",
              type: "success",
            });
            this.$emit("submited");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.show_toast({
            message: err,
            type: "error",
          });
        });
    },
    getEdit() {
      this.get_jobs_position({
        id: this.id,
        provider: this.is_preview
          ? this.preview_personify?.id
          : this.user?.enterprise?.id,
      }).then(({ data: { name, quantity, type } }) => {
        this.name = name;
        this.quantity = quantity;
        this.type = type;
      });
    },
  },

  mounted() {
    if (!this.isAdd) {
      this.getEdit();
    }
  },
};
</script>

<style scoped lang="scss">
.form-data {
  max-width: 600px;
  margin: 0 auto;
}
</style>
